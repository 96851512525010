$color-text: rgb(105, 105, 105);
$color-gold:#ba9f5a;
$color-bg-beige: #f1ece7;
//$color-burger-line: rgba(255, 255, 255, 0.527);

//= burger 
$color-burger-line: rgba(0, 0, 0, 0.699);
$hi-burger-line: 4px;
$background-burger-line: #ffffff2b;

$gap: 1vw;
$gap-mobile: 2vw;





