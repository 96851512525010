//= Hand 
@font-face {
    font-family: "Hand";
    src: url("../resources/fonts/Caveat-Regular.woff2") format("woff2"), url("../resources/fonts/Caveat-Regular.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

//= Title 
@font-face {
    font-family: "Streamster";
    src: url("../resources/fonts/Streamster.woff2") format("woff2"), url("../resources/fonts/Streamster.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

//= Montserrat 
@font-face {
    font-family: "Mont";
    src: url("../resources/fonts/Montserrat-Regular.woff2") format("woff2"), url("../resources/fonts/Montserrat-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mont";
    src: url("../resources/fonts/Montserrat-Medium.woff2") format("woff2"), url("../resources/fonts/Montserrat-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mont";
    src: url("../resources/fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../resources/fonts/Montserrat-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

