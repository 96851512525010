@import 'fonts';

*{
    padding: 0;
    margin: 0;
    border: 0;
    }
    *,*:before,*:after{
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    }
    :focus,:active{outline: none;}
    a:focus,a:active{outline: none;}
    nav,footer,header,aside{display: block;}
    html,body{
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    }
    input,button,textarea{font-family:inherit;}
    input::-ms-clear{display: none;}
    button{cursor: pointer;}
    button::-moz-focus-inner {padding:0;border:0;}
    a, a:visited{text-decoration: none;}
    a:hover{text-decoration: none;}
    ul li{list-style: none;}
    img{vertical-align: top;}
    h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: 400;}
/*--------------------*/

#root{
    height: 100%;
    width: 100%;
}

.wrapper{
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;   
    -khtml-user-select: none;    
    -moz-user-select: none;      
    -ms-user-select: none;       
    user-select: none; 
}

.main{
    flex: 1 1 auto;
    position: relative;
    z-index: 99;
}

.container{
    max-width: 1450px;
    overflow: hidden;
    margin: 0px auto;
    @media (min-width: 1921px) and (max-width: 2600px){
        max-width: 70vw;
    };

    @media (min-width: 2601px){
        max-width: 2400px;
    };

    
}

.container-main{
    max-width: 1600px;
    overflow: hidden;
    margin: 0px auto;
}

.add-wed-all{
    display: flex;
    flex-direction: column;
}


//= Theme                                                             

html {
    --color-background: #fff;

    --color-bg-text: #4d5653;
    --color-bg-list: #f1ece7;

    --color-text: rgb(105, 105, 105);
    --color-text-light: #fff;

    --color-gold: #ba9f5a;

}

// html[data-theme='dark'] {
//     --color-background: #020003;
//     --text-color: white;
// }


// @media (prefers-color-scheme: dark) {
//     :root {
//         // --color-background: #272c32;
//         --color-background: #020003;
//         --text-color: white;
//     }
// }










